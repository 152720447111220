export const WEB_EXPERIENCE = "/proxy-web-experience";
export const USERS_AUTH_API = `${WEB_EXPERIENCE}/users-auth-api/v1`;
export const WEB_PAYMENTS_BACKEND = `${WEB_EXPERIENCE}/web-payments-backend/v1`;
export const PAYMENTS_HISTORY = `${WEB_EXPERIENCE}/payments-history/v2`;
export const HEADER_SUB_NAV_TEAM = (lang: string): string =>
  `/api/${lang}/navigation/teams`;
export const HEADER_SUB_NAV_COMPETITION = (lang: string): string =>
  `/api/${lang}/navigation/competitions`;
export const PAYMENTS_REFUND = `${WEB_EXPERIENCE}/payments-refund/v1/refunds`;

export const SESSIONS_ENDPOINT = `${WEB_EXPERIENCE}/ott-live-sessions/v1/sessions`;
//Bookmarks
export const BOOKMARK_ENDPOINT = "/api/bookmarks";

export const GOOGLE_AUTH = "/api/auth/google";
