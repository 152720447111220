import Script from "next/script";

import {
  AUTO_BLOCK_SCRIPT_URL,
  DATA_DOMAIN_SCRIPT_ID,
  SDK_SCRIPT_URL,
} from "./constants";

export const setupScripts = [
  <Script
    key="onetrust-autoblock-script"
    src={AUTO_BLOCK_SCRIPT_URL}
    strategy="beforeInteractive"
  />,
  <Script
    key="onetrust-cookies-manager-script"
    src={SDK_SCRIPT_URL}
    data-domain-script={DATA_DOMAIN_SCRIPT_ID}
    strategy="beforeInteractive"
  />,
  <Script
    key="onetrust-optanon-event-handler"
    id="dispatch-onetrust-onready-event"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
      function OptanonWrapper() {
        window.dispatchEvent(new Event("onOneTrustReady"));
      };
    `,
    }}
  />,
  <Script
    id="setup-scripts-gtag-DC-15139214-script"
    key={"setup-scripts-gtag-DC-15139214"}
    src="https://www.googletagmanager.com/gtag/js?id=DC-15139214"
    strategy="beforeInteractive"
  />,
  <Script
    id="setup-scripts-gtag-DC-15139214-data"
    key={"setup-scripts-gtag-DC-15139214-data"}
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
          window.dataLayer = window.dataLayer || [];
          window.gtag = window.gtag || function onefootballgtag() {
            if (window.dataLayer !== undefined) {
              window.dataLayer.push(arguments);
            }
          };
          window.gtag('js', new Date());
          window.gtag('config', 'DC-15139214');
        `,
    }}
  />,
];
