export const FoodlightScript = (): JSX.Element => {
  return (
    <div
      id="foodlight-script"
      style={{ display: "none" }}
      dangerouslySetInnerHTML={{
        __html: `
  <!--
Start of Floodlight Tag: Please do not remove
Activity name of this tag: OF_Web_Remarketing_floodlights_Group1
URL of the webpage where the tag is expected to be placed: https://onefootball.com
This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
Creation Date: 01/23/2025
-->
<script type="text/javascript">
try {
var axel = Math.random() + "";
var a = axel * 10000000000000;
document.write(
'<img src="https://ad.doubleclick.net/ddm/activity/src=15139214;type=invmedia;cat=of_we0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1;num=' +
a +
'?" width="1" height="1" alt=""/>'
);
} catch(err) { /** ... **/ }
</script>
<noscript>
<img
src="https://ad.doubleclick.net/ddm/activity/src=15139214;type=invmedia;cat=of_we0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1;num=1?"
width="1"
height="1"
alt=""
/>
</noscript>
<!-- End of Floodlight Tag: Please do not remove -->
`,
      }}
    ></div>
  );
};
